import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

export const FILE_SIZE = 210 * 1024 * 1024

class DocumentsApi {
  getAdminDocuments = async (msalInstance, accounts, unitId) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .get(`${env.urls.documentsURL}/${unitId}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  getMyDocuments = async (msalInstance, accounts, unitId) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .get(`${env.urls.documentsURL}/${unitId}/mine`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
  }

  getDocument = async (msal, accounts, name, unitId) => {
    let token = await acquireAccessToken(msal, accounts, env)
    return await axios
      .get(`${env.urls.documentsURL}/${unitId}/${name}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
  }

  deleteDocument = async (msal, accounts, doc, unitId) => {
    let token = await acquireAccessToken(msal, accounts, env)
    return await axios
      .delete(`${env.urls.documentsURL}/${unitId}/${doc.id}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
  }

  createInfo = async (msal, accounts, doc, unitId) => {
    let token = await acquireAccessToken(msal, accounts, env)

    return await axios
      .post(`${env.urls.documentsURL}/${unitId}/info`, doc, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
  }

  updateInfo = async (msal, accounts, doc, unitId) => {
    let token = await acquireAccessToken(msal, accounts, env)

    return await axios
      .put(`${env.urls.documentsURL}/${unitId}/info`, doc, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
  }

  uploadDocument = async (msal, accounts, contentType, data, docId, unitId) => {
    let token = await acquireAccessToken(msal, accounts, env)
    return await axios({
      url: `${env.urls.documentsURL}/${unitId}/file/${docId}`,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': contentType,
        Authorization: `Bearer ${token}`
      },
      maxContentLength: FILE_SIZE,
      maxBodyLength: FILE_SIZE
    })
  }

  updateDocument = async (msal, accounts, contentType, data, docId, unitId) => {
    let token = await acquireAccessToken(msal, accounts, env)
    return await axios({
      url: `${env.urls.documentsURL}/${unitId}/file/update/${docId}`,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': contentType,
        Authorization: `Bearer ${token}`
      },
      maxContentLength: FILE_SIZE,
      maxBodyLength: FILE_SIZE
    })
  }

  downloadDocument = async (msal, accounts, data, unitId) => {
    let token = await acquireAccessToken(msal, accounts, env)
    var form = new FormData()
    form.append('token', token)
    form.append('useMime', false)
    return await axios({
      url: `${env.urls.documentsURL}/${unitId}/file/throughtoken/${data.id}`,
      method: 'POST',
      data: form,
      responseType: 'blob',
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      const href = URL.createObjectURL(response.data)

      // create "a" HTLM element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', data.fileName) //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
  }

  addUserOverride = async (msal, accounts, id, userId, unitId) => {
    let token = await acquireAccessToken(msal, accounts, env)

    return await axios
      .post(
        `${env.urls.documentsURL}/${unitId}/${id}/addoverride/${userId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
          timeout: 30000
        }
      )
      .then(result => result.data)
  }

  removeUserOverride = async (msal, accounts, id, userId, unitId) => {
    let token = await acquireAccessToken(msal, accounts, env)

    return await axios
      .post(
        `${env.urls.documentsURL}/${unitId}/${id}/removeoverride/${userId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
          timeout: 30000
        }
      )
      .then(result => result.data)
  }

  removeDocRefFromPackages = async (msal, accounts, id) => {
    let token = await acquireAccessToken(msal, accounts, env)

    return await axios
      .delete(`${env.urls.documentsURL}/packages/deletedoc/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
  }
}
const getInstance = () => {
  if (instance == null) {
    instance = new DocumentsApi()
  }
  return instance
}

export default getInstance()
