import AdminDocumentsPage from './pages/AdminDocumentsPage'

export default function DocumentsPage(props) {
  return (
    <AdminDocumentsPage
      styles={props.styles}
      showFullList={props.showFullList}
      productState={props.productState}
      setProductState={props.setProductState}
    />
  )
}
