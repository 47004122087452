import { useState } from 'react'
import { env } from '../../index.js'
import { DownloadButtonWtx } from './DownloadButtonWtx'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Icon, TextField, IconButton } from '@mui/material'
import { useWtxLocalization } from '@wavetronix/common-components'
import { getVideoType } from '../../utils/stringUtils'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import PlayVideoModal from '../modals/PlayVideoModal'

const classes = {
  docBlockStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      cursor: 'pointer'
    },
    borderBottom: '2px solid #E0E0E0'
  },
  downloadLink: {
    color: '#4B7FDE',
    marginLeft: 10,
    marginTop: 0,
    fontSize: '14px',
    wordBreak: 'break-word',
    textAlign: 'left'
  },
  buttonStyle: {
    backgroundColor: '#fff',
    color: '#E0E0E0',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#E0E0E0'
    },
    '&:click': {
      backgroundColor: '#fff'
    }
  }
}

export default function DocumentTypeCard({
  documentsCategory,
  documents,
  token,
  reorderMap,
  setReorderMap,
  productId,
  productName
}) {
  const navigate = useNavigate()
  const { unitId } = useParams()
  const [videoInfo, setVideoInfo] = useState(null)
  const [videoDialogVisible, setVideoDialogVisible] = useState(false)

  let localizedStrings = useWtxLocalization()

  const changePosition = async (doc, newPos) => {
    let value = parseInt(newPos)

    if (isNaN(value) === false) {
      setReorderMap(doc.id, { ...doc, order: value })
    }
  }

  return (
    <>
      <PlayVideoModal
        videoInfo={videoInfo}
        open={videoDialogVisible}
        onClose={() => {
          setVideoDialogVisible(false)
          setVideoInfo(null)
        }}
        token={token}
        unitId={unitId}
      />
      <div>
        <h3 style={{ fontSize: '18px', marginBlockEnd: '0' }}>{documentsCategory}</h3>
        <div>
          <>
            {documents
              ? documents.map(doc => {
                  var downloadUrl = `${env.urls.documentsURL}/${unitId}/file/throughtoken/${doc.id}`
                  return (
                    <Box key={doc.id} sx={classes.docBlockStyle}>
                      {getVideoType(doc.id).isSuccess === true ? (
                        <IconButton
                          id={`${doc.id}PlayDownloadButton`}
                          disableRipple
                          sx={classes.buttonStyle}
                          size='large'
                          className='btn-link'
                          color='primary'
                          onClick={() => {
                            setVideoInfo(doc)
                            setVideoDialogVisible(true)
                          }}
                        >
                          <PlayCircleOutlineIcon />
                          <div style={{ ...classes.downloadLink, marginLeft: 10, marginTop: 5 }}>{doc.fileName}</div>
                        </IconButton>
                      ) : (
                        <DownloadButtonWtx
                          id={`${doc.id}PlayDownloadButton`}
                          url={downloadUrl}
                          token={token}
                          style={classes}
                          fileName={doc.fileName}
                        />
                      )}
                      <Icon
                        id={`${doc.id}EditLink`}
                        style={{ marginTop: 15, marginRight: '0px', marginLeft: 'auto' }}
                        onClick={e =>
                          navigate(
                            `/details/${unitId}?id=${encodeURIComponent(doc.id)}&prev=byproduct&productId=${encodeURIComponent(
                              productId
                            )}&productName=${encodeURIComponent(productName)}`
                          )
                        }
                      >
                        edit
                      </Icon>
                      <TextField
                        id={`${doc.id}OrderTextField`}
                        inputProps={{ min: 1 }}
                        type='number'
                        style={{ width: '80px', margin: '10px' }}
                        variant='outlined'
                        onChange={e => {
                          changePosition(doc, e.target.value)
                        }}
                        label={localizedStrings.order}
                        required
                        value={reorderMap && reorderMap[doc.id] ? reorderMap[doc.id].order : ''}
                        size='small'
                      />
                    </Box>
                  )
                })
              : []}
          </>
        </div>
      </div>
    </>
  )
}
