const en = {
  title: 'Documents Manager',
  viewProductDownloads: 'View product downloads',
  back: 'Back to',
  customerNoDocs: {
    info: "Didn't find what you were looking for?",
    prompt: 'Call our technical support team at (801) 734-7222'
  },
  adminNoDocs: {
    info: 'There are currently no files under',
    prompt: 'Press the upload button to add files to this product.'
  },
  uploadDoc: 'Upload Document',
  availableDocs: 'Search Documents',
  name: 'Name',
  description: 'Description',
  accessLevel: 'Access Level',
  associatedProducts: 'Associated Products',
  documentType: 'Document Type',
  products: 'Products',
  accessories: 'Accessories',
  languages: 'Languages',
  download: 'Download',
  manageUsers: 'Manage Users',
  firstName: 'First Name',
  lastName: 'Last Name',
  filter: 'Filter',
  clear: 'Clear',
  apply: 'Apply',
  cancel: 'Cancel',
  manage: 'Manage',
  category: 'Category',
  addCategory: 'Add Category',
  productFamily: 'Product Family',
  chooseImage: 'Choose Image',
  add: 'Add',
  override: 'Override',
  snackbar: {
    categoryCreated: 'Category created successfully!',
    categoryCreateFailed: 'Create category failed',
    categoryDeleted: 'Category deleted successfully!',
    categoryDeletedFailed: 'Delete category failed',
    categoryUpdated: 'Category updated successfully!',
    categoryUpdatedFailed: 'Update category failed',
    imageUploaded: 'Image uploaded successfully!',
    imageUploadedFailed: 'Image upload failed',
    infoUploaded: 'Info uploaded successfully!',
    infoUploadedFailed: 'Document Info upload failed.',
    docUploaded: 'File uploaded successfully!',
    docUploadedFailed: 'Unknown error occured! Possible file with duplicate name.',
    updatingUser: 'Updating user...',
    updatedUser: 'Successfully updated user',
    updatedUserFailed: 'Failed to update user',
    previewNotSupported: 'Preview for this file type is not supported',
    docDeleted: 'File deleted successfully!',
    docInfoUpdate: 'Updating document information...',
    docInfoUpdateFailed: 'Failed to update document',
    docInfoUpdated: 'Successfully updated document',
    docsInfoUpdateFailed: 'Failed to update all documents',
    docsInfoUpdated: 'Successfully updated all documents',
    publicURLCopied: 'Public Access URL copied!',
    overrideSuccess: 'Successfully added override to document',
    overrideRemoveSuccess: 'Successfully removed override from document',
    overrideFailure: 'Failure adding override to document',
    overrideRemoveFailure: 'Failure removing override from document',
    loadingVideo: 'Loading video...'
  },
  updateCategory: 'Update Category',
  chooseFile: 'Choose File',
  save: 'Save',
  preview: 'Preview Document',
  deleteDocument: 'Delete Document',
  delete: 'Delete',
  updateDocument: 'Update File',
  order: 'Order',
  listOrder: 'List Order',
  email: 'Email',
  documents: 'Documents',
  search: 'Search',
  categories: 'Categories',
  users: 'Users',
  crmproducts: 'CRM Products',
  assets: 'Assets',
  productId: 'Product Id',
  workInstruction: 'Work Instruction',
  downloadSelected: 'Download Selected',
  deleteSelected: 'Delete Selected',
  deleteAllMessage:
    'All documents listed below will be permently deleted from the system and references to them will be removed from all groups and users. This action is permanent and cannot be undone.'
}
export { en }
