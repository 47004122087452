import { useMsal } from '@azure/msal-react'
import { CircularProgress, Tooltip } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Card, TextField, Stack } from '@mui/material'
import {
  CenteredDiv,
  CustomSelect,
  PrimaryButton,
  SnackbarVariants,
  useWtxLocalization,
  DeleteButton
} from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import { useMemo } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import DocumentsApi from '../../api/DocumentsApi'
import BackButton from '../controls/BackButton'
import ProductTypeSelector from '../controls/ProductTypeSelector'
import CrmProductTypeSelector from '../controls/CrmProductTypeSelector'
import DocumentTypeSelect from '../DocumentTypeSelect'
import { env } from '../../index.js'
import FileUploader from '../controls/FileUploader'

export function DocumentInfoSection({
  unitId,
  doc,
  setDoc,
  isUploading,
  categories,
  crmProducts,
  setDeleteModalOpen,
  setButtonsDisabled,
  createDocInfo,
  docRefetch
}) {
  let localizedStrings = useWtxLocalization()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { instance, accounts } = useMsal()

  const categoriesMap = useMemo(() => {
    if (categories) {
      return categories.reduce((map, obj) => {
        map[obj.id] = obj
        return map
      }, {})
    }
  }, [categories])

  const { documentTypes, productTypes, languageTypes } = useMemo(() => {
    let docTypes = []
    let prodTypes = []
    let langTypes = []

    if (categories) {
      docTypes = categories.filter(c => c.categoryType === 'Document Type').sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1))
      prodTypes = categories.filter(c => c.categoryType === 'Product').sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1))
      langTypes = categories.filter(c => c.categoryType === 'Language').sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1))
    }
    return { documentTypes: docTypes, productTypes: prodTypes, languageTypes: langTypes }
  }, [categories])

  const invalidDocInfo = useMemo(() => {
    let baseCheck = isUploading
    let unitCheck = false

    if (doc) {
      if (unitId === 'nexus' && doc.products && doc.language && doc.documentType) {
        unitCheck = doc.products.length <= 0 || doc.language.length <= 0 || !doc.documentType
      } else if (unitId === 'manufacturing' && doc.productId && doc.workInstruction) {
        unitCheck = doc.productId === '' || doc.workInstruction === ''
      } else {
        unitCheck = true
      }
    }
    return baseCheck || unitCheck
  }, [isUploading, doc, unitId])

  function setDocProducts(productTypes) {
    setDoc({ ...doc, products: productTypes })
  }

  function setDocCRMProducts(productTypes) {
    setDoc({ ...doc, crmProducts: productTypes })
  }

  function setDocLanguages(languageTypes) {
    setDoc({ ...doc, language: languageTypes })
  }

  const unitComponents = {
    section1: {
      nexus: (
        <CenteredDiv>
          <CustomSelect
            id='docAccessLevelSelect'
            required
            style={{ width: '100%', marginTop: 15 }}
            label={localizedStrings.accessLevel}
            value={doc.accessLevel}
            onChange={e => setDoc(d => ({ ...d, accessLevel: e.target.value }))}
            options={['Basic', 'Intermediate', 'Advanced']}
          />
          <TextField
            id='docOrderTextField'
            inputProps={{ min: 1 }}
            type='number'
            variant='outlined'
            style={{ width: '18%', marginLeft: 15, marginTop: 15 }}
            onChange={e => setDoc(d => ({ ...d, order: e.target.value }))}
            label={localizedStrings.order}
            required
            value={doc.order}
            size='small'
          />
        </CenteredDiv>
      ),
      manufacturing: (
        <CenteredDiv>
          <Stack spacing={2} direction='row' sx={{ width: '100%' }}>
            <TextField
              id='docProductIdLevelSelect'
              label={localizedStrings.productId}
              required={true}
              disabled={isUploading}
              value={doc && doc.productId ? doc.productId : ''}
              onChange={e => setDoc(info => ({ ...info, productId: e.target.value }))}
              size='small'
              sx={{ width: '45%' }}
            />
            <TextField
              id='docWorkInstructionLevelSelect'
              label={localizedStrings.workInstruction}
              required={true}
              disabled={isUploading}
              value={doc && doc.workInstruction ? doc.workInstruction : ''}
              onChange={e => setDoc(info => ({ ...info, workInstruction: e.target.value }))}
              size='small'
              sx={{ width: '45%' }}
            />
            <TextField
              id='docOrderTextField'
              inputProps={{ min: 1 }}
              type='number'
              disabled={isUploading}
              variant='outlined'
              onChange={e => setDoc(info => ({ ...info, order: e.target.value }))}
              label={localizedStrings.order}
              required
              value={doc && doc.order ? doc.order : ''}
              size='small'
              sx={{ width: 'auto' }}
            />
          </Stack>
        </CenteredDiv>
      )
    },
    section2: (
      <CenteredDiv>
        <ProductTypeSelector
          title={localizedStrings.products}
          selectorStyle={{ display: 'flex', width: '100%', marginTop: 15 }}
          documentProductTypes={categoriesMap ? doc.products.map(id => categoriesMap[id]) : []}
          productTypes={productTypes}
          onChange={selectedProductTypes => {
            setDocProducts(selectedProductTypes.map(prod => prod.id))
          }}
          docId={doc.id}
        />
      </CenteredDiv>
    ),
    section3: (
      <CenteredDiv>
        <CrmProductTypeSelector
          title={'CRM Products'}
          selectorStyle={{ display: 'flex', width: '100%', marginTop: 15 }}
          documentProductTypes={doc.crmProducts}
          productTypes={crmProducts}
          onChange={crmTypes => {
            return setDocCRMProducts([...crmTypes.map(t => t.id)])
          }}
          docId={doc.id}
        />
      </CenteredDiv>
    ),
    section4: {
      nexus: (
        <CenteredDiv>
          <DocumentTypeSelect
            style={{ width: '100%', marginTop: 15 }}
            required
            currentValue={categoriesMap && categoriesMap[doc.documentType] ? categoriesMap[doc.documentType] : null}
            documentTypes={documentTypes}
            onChange={item => {
              setDoc({ ...doc, documentType: item.id })
            }}
          />
        </CenteredDiv>
      ),
      manufacturing: null
    },
    section5: {
      nexus: (
        <CenteredDiv>
          <ProductTypeSelector
            title={localizedStrings.languages}
            selectorStyle={{ display: 'flex', width: '100%', marginTop: 15 }}
            documentProductTypes={categoriesMap && doc && doc.language ? doc.language.map(id => categoriesMap[id]) : []}
            productTypes={languageTypes}
            onChange={selectedLanguages => {
              console.log(selectedLanguages)
              setDocLanguages(selectedLanguages.map(lang => lang.id))
            }}
            docId={doc.id}
          />
        </CenteredDiv>
      ),
      manufacturing: null
    },
    section6: (
      <TextField
        id='docDescriptionTextField'
        style={{ marginTop: 15, width: '100%' }}
        label={localizedStrings.description}
        variant='outlined'
        multiline
        rows={4}
        value={doc.description}
        onChange={e => setDoc(d => ({ ...d, description: e.target.value }))}
        required
      />
    ),
    section7: (
      <div style={{ marginTop: 15 }}>
        <DeleteButton id='deleteModalOpenButton' onClick={() => setDeleteModalOpen(true)}>
          {localizedStrings.deleteDocument}
        </DeleteButton>
        <PrimaryButton
          id='docChangesSaveButton'
          style={{ float: 'right', minWidth: '150px' }}
          disabled={invalidDocInfo}
          onClick={async e => {
            setButtonsDisabled(true)
            let key = enqueueSnackbar(localizedStrings.snackbar.docInfoUpdate, {
              variant: 'default',
              anchorOrigin: { vertical: 'top', horizontal: 'center' },
              action: <CircularProgress />,
              persist: true
            })
            const newDoc = createDocInfo()
            await DocumentsApi.updateInfo(instance, accounts, newDoc, unitId)
              .catch(async () => {
                enqueueSnackbar(localizedStrings.snackbar.docInfoUpdateFailed, SnackbarVariants.ERROR)
              })
              .then(async () => {
                enqueueSnackbar(localizedStrings.snackbar.docInfoUpdated, SnackbarVariants.SUCCESS)
              })
              .finally(() => {
                docRefetch()
                setButtonsDisabled(false)
              })
            closeSnackbar(key)
          }}
        >
          {localizedStrings.save}
        </PrimaryButton>
      </div>
    )
  }
  return (
    <Card style={{ margin: '0px 15px 15px 15px', padding: 15 }}>
      {unitComponents.section1[unitId]}
      {unitComponents.section2}
      {unitComponents.section3}
      {unitComponents.section4[unitId]}
      {unitComponents.section5[unitId]}
      {unitComponents.section6}
      {unitComponents.section7}
    </Card>
  )
}

export function DocumentNameSection({ unitId, doc, uploadWithFormData, isUploading, searchParams }) {
  let localizedStrings = useWtxLocalization()
  const { enqueueSnackbar } = useSnackbar()

  let prev = searchParams.get('prev')
  let productId = searchParams.get('productId')
  let productName = searchParams.get('productName')

  return (
    <div>
      <BackButton
        previous={prev === 'byproduct' ? `${productName}` : `Search`}
        path={prev === 'byproduct' ? `/byproduct/${unitId}?name=${productName}&id=${productId}` : `../${prev}/${unitId}`}
        onClick={() => {}}
      />
      <div style={{ display: 'flex' }}>
        {unitId === 'nexus' && doc.accessLevel === 'Basic' ? (
          <div style={{ display: 'flex' }}>
            <h2 style={{ marginLeft: 15, marginTop: 0 }}>{doc.fileName}</h2>

            <CopyToClipboard
              id={`${doc.id}CopyToClipboard`}
              text={`${env.urls.documentsURL}/${unitId}/file/public/${encodeURIComponent(doc.id)}`}
              onCopy={() => enqueueSnackbar(localizedStrings.snackbar.publicURLCopied, SnackbarVariants.SUCCESS)}
            >
              <Tooltip title='Public Access URL'>
                <ContentCopyIcon style={{ marginBottom: 'auto', marginTop: 5, marginLeft: '15px', cursor: 'pointer' }} />
              </Tooltip>
            </CopyToClipboard>
          </div>
        ) : (
          <h2 style={{ marginLeft: 15, marginTop: 0 }}>{doc.fileName}</h2>
        )}

        <FileUploader
          disabled={isUploading}
          handleFile={async file => {
            await uploadWithFormData(file)
          }}
          fileTypes='*'
          prompt={localizedStrings.chooseFile}
          useIcon={true}
          iconStyle={{ marginLeft: '10px' }}
          iconToolTip={localizedStrings.updateDocument}
        />
      </div>
    </div>
  )
}
