import { useMsal } from '@azure/msal-react'
import {
  CenteredDiv,
  CustomDataGrid,
  ErrorMessage,
  RegularButton,
  RoleCheck,
  useWtxLocalization,
  PersistantFilterDiv
} from '@wavetronix/common-components'
import { useState, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import CrmProductsApi from '../../api/CrmProductsApi'
import CategoriesApi from '../../api/CategoriesApi'
import CrmProductsFilterDrawer, { DEFAULT_CRMPRODUCTS_FILTER, filterCrmProducts } from '../drawers/CrmProductsFilterDrawer'
import EditCrmProductModal from '../modals/EditCrmProductModal'
import { CircularProgress, Box } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { env } from '../../index.js'

export default function CrmProductsManagementPage(props) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
  let largeScreenFeaturesActive = !isSmallScreen

  const { instance, accounts } = useMsal()
  const [filter, setFilter] = useState(DEFAULT_CRMPRODUCTS_FILTER)
  const [editCategoryModalVisible, setEditCategoryModalVisible] = useState(false)
  const [currentCategory, setCurrentCategory] = useState()
  let localizedStrings = useWtxLocalization()

  const columns = [
    {
      headerName: localizedStrings.name,
      field: 'name',
      flex: 1,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive
    },
    {
      headerName: 'Product Item Numbers',
      field: 'productItemNumbers',
      flex: 2,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive,
      valueGetter: u => u.value.join(', ')
    },
    {
      headerName: localizedStrings.productFamily,
      field: 'family',
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
      hide: isSmallScreen
    },
    {
      headerName: 'List Order',
      field: 'listOrder',
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
      hide: isSmallScreen
    }
  ]

  const { data, refetch, error, isLoading } = useQuery({
    queryKey: ['crmProductsMap'],
    queryFn: async () =>
      await CrmProductsApi.getCrmProducts(instance, accounts).then(res => {
        if (res.length !== 0) {
          return res.reduce((map, obj) => {
            map[obj.id] = obj
            return map
          }, {})
        }
        return res
      })
  })

  const { data: categoriesData } = useQuery({
    queryKey: ['categories'],
    queryFn: async () =>
      await CategoriesApi.getCategories(instance, accounts).then(res => {
        return res
      })
  })

  const categoryFamilies = useMemo(() => {
    let res = []
    if (categoriesData) {
      res =
        filter.showIsArchived === false
          ? categoriesData.filter(c => c.categoryType === 'Product Family' && c.isArchived === false)
          : categoriesData.filter(c => c.categoryType === 'Product Family')
    }
    return res.sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1))
  }, [filter, categoriesData])

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }
  if (isLoading) {
    return (
      <CenteredDiv>
        <CircularProgress />
      </CenteredDiv>
    )
  }

  return (
    <RoleCheck env={env} allowedRoles={['Documents Admin', 'Gatekeeper Admin']}>
      <EditCrmProductModal
        open={editCategoryModalVisible}
        onClose={() => setEditCategoryModalVisible(false)}
        styles={props.styles}
        refetch={refetch}
        current={currentCategory ? currentCategory : null}
        categoryFamlies={categoryFamilies ? categoryFamilies : []}
      />
      <div style={{ display: 'flex' }}>
        <PersistantFilterDiv
          defaultOpen={largeScreenFeaturesActive}
          page={
            <div style={{ width: '100%' }}>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ flex: '1 1 auto' }} />

                <div style={{ display: 'flex', float: 'right' }}>
                  <div style={{ display: 'inline', margin: '24px 0px 12px 12px' }}>
                    <RegularButton
                      id='editCategoryModalVisibleButton'
                      onClick={() => setEditCategoryModalVisible(true)}
                      style={{ marginRight: '24px', display: 'hidden' }}
                    >
                      Add CRM Product
                    </RegularButton>
                  </div>
                </div>
              </Box>

              <CustomDataGrid
                rows={
                  data ? filterCrmProducts(filter, Object.values(data)).sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1)) : []
                }
                style={{ marginLeft: '24px' }}
                loading={isLoading}
                columns={columns}
                onRowClick={cat => {
                  setCurrentCategory(cat.row)
                  setEditCategoryModalVisible(true)
                }}
                cursor='pointer'
              />
            </div>
          }
          drawer={
            <CrmProductsFilterDrawer
              filter={filter}
              setFilter={setFilter}
              categoryFamlies={categoryFamilies ? categoryFamilies : []}
            />
          }
          resetFilter={() => setFilter(DEFAULT_CRMPRODUCTS_FILTER)}
        />
      </div>
    </RoleCheck>
  )
}
