import { Tooltip, IconButton } from '@mui/material'
import {
  CustomDataGrid,
  RegularButton,
  acquireAccessToken,
  parseJwt,
  SnackbarVariants,
  useWtxLocalization,
  PersistantFilterDiv,
  DeleteButton
} from '@wavetronix/common-components'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import RemoveIcon from '@mui/icons-material/Remove'
import { useEffect, useState, useMemo } from 'react'
import { useMsal } from '@azure/msal-react'
import { env } from '../../index.js'
import CopyToClipboard from 'react-copy-to-clipboard'
import DocumentsApi from '../../api/DocumentsApi'
import CategoriesApi from '../../api/CategoriesApi'
import DocumentsFilterDrawer, { DEFAULT_DOCUMENTS_FILTER, filterDocuments } from '../drawers/DocumentFilterDrawer'
import MultiDeleteModal from '../modals/MultiDeleteModal'
import PlayVideoModal from '../modals/PlayVideoModal'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { getVideoType } from '../../utils/stringUtils'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { DownloadButtonWtx } from '../controls/DownloadButtonWtx'

export default function DocumentsListDisplayPage({ filter, setFilter, cookies, setCookie, ...props }) {
  const theme = useTheme()
  const { unitId } = useParams()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
  let largeScreenFeaturesActive = !isSmallScreen
  const { instance, accounts } = useMsal()
  const [mouseIsOverDownloadButton, setMouseIsOverDownloadButton] = useState(false)
  const [downloadToken, setDownloadToken] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const [selectedRows, setSelectedRows] = useState([])
  const [multiDeleteVisible, setMultiDeleteVisible] = useState(false)
  const [videoInfo, setVideoInfo] = useState(null)
  const [videoDialogVisible, setVideoDialogVisible] = useState(false)

  let localizedStrings = useWtxLocalization()

  useEffect(() => {
    if (cookies && cookies['filter']) {
      setFilter(cookies['filter'])
    }
  }, [cookies, setFilter])

  const { data: categories } = useQuery({
    queryKey: ['categories'],
    queryFn: async () => await CategoriesApi.getCategories(instance, accounts)
  })

  const productTypes = useMemo(() => {
    let res = []
    if (categories && filter) {
      res =
        filter.showIsArchived === false
          ? categories.filter(c => c.categoryType === 'Product' && c.isArchived === false)
          : categories.filter(c => c.categoryType === 'Product')
    }
    return res.sort((a, b) => (a.order > b.order ? 1 : -1))
  }, [filter, categories])

  useEffect(() => {
    async function resetDownloadToken() {
      let token = await acquireAccessToken(instance, accounts, env)
      setDownloadToken(token)
    }

    if (!downloadToken || parseJwt(downloadToken).exp < Date.now() / 1000) {
      resetDownloadToken()
    }
  }, [instance, accounts, downloadToken, setCookie])

  const categoryMap = useMemo(() => {
    if (categories) {
      return categories.reduce((map, value) => {
        map[value.id] = value
        return map
      }, {})
    }
  }, [categories])

  const columns = {
    nexus: [
      {
        headerName: 'URL',
        field: 'id',
        flex: 0.25,
        hide: isSmallScreen,
        filterable: false,
        disableColumnMenu: true,
        renderCell: u => {
          return (
            <div>
              {u.row.accessLevel === 'Basic' ? (
                <CopyToClipboard
                  id={`${u.id}UriCopyToClipboard`}
                  text={`${env.urls.documentsURL}/${unitId}/file/public/${encodeURIComponent(u.id)}`}
                  onCopy={() => enqueueSnackbar(localizedStrings.snackbar.publicURLCopied, SnackbarVariants.SUCCESS)}
                >
                  <Tooltip title='Public Access URL'>
                    <ContentCopyIcon
                      onMouseEnter={() => setMouseIsOverDownloadButton(true)}
                      onMouseLeave={() => setMouseIsOverDownloadButton(false)}
                      style={{ cursor: 'pointer', float: 'right', marginLeft: '10px' }}
                    />
                  </Tooltip>
                </CopyToClipboard>
              ) : (
                <RemoveIcon
                  onMouseEnter={() => setMouseIsOverDownloadButton(true)}
                  onMouseLeave={() => setMouseIsOverDownloadButton(false)}
                  style={{ cursor: 'pointer', float: 'right', marginLeft: '10px' }}
                />
              )}
            </div>
          )
        }
      },
      {
        headerName: localizedStrings.order,
        field: 'order',
        flex: 0.5,
        hide: isSmallScreen,
        filterable: false,
        disableColumnMenu: true
      },
      {
        headerName: localizedStrings.name,
        field: 'fileName',
        flex: 2,
        filterable: false,
        disableColumnMenu: largeScreenFeaturesActive
      },

      {
        headerName: localizedStrings.description,
        field: 'description',
        flex: 2,
        hide: isSmallScreen,
        filterable: false,
        disableColumnMenu: true
      },
      {
        headerName: localizedStrings.accessLevel,
        field: 'accessLevel',
        flex: 1,
        hide: isSmallScreen,
        filterable: false,
        disableColumnMenu: true
      },
      {
        headerName: localizedStrings.associatedProducts,
        field: 'productTypes',
        flex: 2,
        hide: isSmallScreen,
        filterable: false,
        disableColumnMenu: true,
        renderCell: u => {
          let products = u.row.products
            ? u.row.products.map(pId => {
                if (categoryMap && categoryMap[pId]) {
                  if (
                    categoryMap[pId].localization &&
                    categoryMap[pId].localization[window.navigator.language] &&
                    categoryMap[pId].localization[window.navigator.language] !== ''
                  ) {
                    return categoryMap[pId].localization[window.navigator.language]
                  } else {
                    return categoryMap[pId].name
                  }
                } else {
                  return pId
                }
              })
            : []
          return <div>{products.join(', ')}</div>
        }
      },
      {
        headerName: localizedStrings.documentType,
        field: 'docType',
        flex: 1,
        hide: isSmallScreen,
        filterable: false,
        disableColumnMenu: true,
        renderCell: u => {
          let id = u.row.documentType
          let name = u.row.documentType
          if (categoryMap && categoryMap[id]) {
            if (
              categoryMap[id].localization &&
              categoryMap[id].localization[window.navigator.language] &&
              categoryMap[id].localization[window.navigator.language] !== ''
            ) {
              name = categoryMap[id].localization[window.navigator.language]
            } else {
              name = categoryMap[id].name
            }
          }
          return <div>{name}</div>
        }
      },
      {
        headerName: localizedStrings.download,
        field: 'download',
        flex: 1,
        filterable: false,
        disableColumnMenu: largeScreenFeaturesActive,
        renderCell: u => {
          if (getVideoType(u.id).isSuccess) {
            return (
              <IconButton
                id={`${u.id}PlayDownloadButton`}
                className='btn-link'
                color='primary'
                size='large'
                onMouseEnter={() => setMouseIsOverDownloadButton(true)}
                onMouseLeave={() => setMouseIsOverDownloadButton(false)}
                onClick={() => {
                  setVideoInfo(u.row)
                  setVideoDialogVisible(true)
                }}
              >
                <PlayCircleOutlineIcon />
              </IconButton>
            )
          } else {
            var downloadUrl = `${env.urls.documentsURL}/${unitId}/file/throughtoken/${u.id}`
            return (
              <DownloadButtonWtx
                fileName={u.row.fileName}
                id={`${u.id}PlayDownloadButton`}
                url={downloadUrl}
                token={downloadToken}
                variant='icon'
                onMouseEnter={() => setMouseIsOverDownloadButton(true)}
                onMouseLeave={() => setMouseIsOverDownloadButton(false)}
              ></DownloadButtonWtx>
            )
          }
        }
      }
    ],
    manufacturing: [
      {
        headerName: localizedStrings.order,
        field: 'order',
        flex: 0.5,
        hide: isSmallScreen,
        filterable: false,
        disableColumnMenu: true
      },
      {
        headerName: localizedStrings.name,
        field: 'fileName',
        flex: 2,
        filterable: false,
        disableColumnMenu: largeScreenFeaturesActive
      },
      {
        headerName: localizedStrings.productId,
        field: 'productId',
        flex: 2,
        filterable: false,
        disableColumnMenu: largeScreenFeaturesActive
      },
      {
        headerName: localizedStrings.workInstruction,
        field: 'workInstruction',
        flex: 2,
        filterable: false,
        disableColumnMenu: largeScreenFeaturesActive
      },
      {
        headerName: localizedStrings.description,
        field: 'description',
        flex: 2,
        hide: isSmallScreen,
        filterable: false,
        disableColumnMenu: true
      },
      {
        headerName: localizedStrings.associatedProducts,
        field: 'productTypes',
        flex: 2,
        hide: isSmallScreen,
        filterable: false,
        disableColumnMenu: true,
        renderCell: u => {
          let products = u.row.products
            ? u.row.products.map(pId => {
                if (categoryMap && categoryMap[pId]) {
                  if (
                    categoryMap[pId].localization &&
                    categoryMap[pId].localization[window.navigator.language] &&
                    categoryMap[pId].localization[window.navigator.language] !== ''
                  ) {
                    return categoryMap[pId].localization[window.navigator.language]
                  } else {
                    return categoryMap[pId].name
                  }
                } else {
                  return pId
                }
              })
            : []
          return <div>{products.join(', ')}</div>
        }
      },
      {
        headerName: localizedStrings.download,
        field: 'download',
        flex: 1,
        filterable: false,
        disableColumnMenu: largeScreenFeaturesActive,
        renderCell: u => {
          if (getVideoType(u.id).isSuccess) {
            return (
              <IconButton
                id={`${u.id}PlayDownloadButton`}
                className='btn-link'
                color='primary'
                size='large'
                onMouseEnter={() => setMouseIsOverDownloadButton(true)}
                onMouseLeave={() => setMouseIsOverDownloadButton(false)}
                onClick={() => {
                  setVideoInfo(u.row)
                  setVideoDialogVisible(true)
                }}
              >
                <PlayCircleOutlineIcon />
              </IconButton>
            )
          } else {
            var downloadUrl = `${env.urls.documentsURL}/${unitId}/file/throughtoken/${u.id}`
            return (
              <DownloadButtonWtx
                fileName={u.row.fileName}
                id={`${u.id}PlayDownloadButton`}
                url={downloadUrl}
                token={downloadToken}
                variant='icon'
                onMouseEnter={() => setMouseIsOverDownloadButton(true)}
                onMouseLeave={() => setMouseIsOverDownloadButton(false)}
              ></DownloadButtonWtx>
            )
          }
        }
      }
    ]
  }

  return (
    <>
      <PlayVideoModal
        token={downloadToken}
        videoInfo={videoInfo}
        open={videoDialogVisible}
        onClose={() => {
          setVideoDialogVisible(false)
          setVideoInfo(null)
        }}
        unitId={unitId}
      />
      <MultiDeleteModal
        open={multiDeleteVisible}
        onClose={() => setMultiDeleteVisible(false)}
        deleteDocuments={selectedRows}
        refetchDocuments={props.refetch}
      />
      <PersistantFilterDiv
        defaultOpen={largeScreenFeaturesActive}
        page={
          <>
            <div>
              <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                <div style={{ justifyContent: 'left', flex: 1, margin: '20px 20px 0px 24px' }}>
                  <RegularButton
                    id='multiSelectedDownloadButton'
                    disabled={selectedRows.length === 0}
                    onClick={async () => {
                      return await Promise.all(
                        selectedRows.map(doc => DocumentsApi.downloadDocument(instance, accounts, doc, unitId))
                      )
                    }}
                  >
                    {localizedStrings.downloadSelected}
                  </RegularButton>
                  <DeleteButton
                    id='multiSelectedDeleteButton'
                    disabled={selectedRows.length === 0}
                    style={{ marginLeft: '15px' }}
                    onClick={() => setMultiDeleteVisible(true)}
                  >
                    {localizedStrings.deleteSelected}
                  </DeleteButton>
                </div>
                <div style={{ display: 'inline', float: 'right' }}>
                  <RegularButton
                    id='openCreateModalVisibleButton'
                    onClick={props.openCreateModal}
                    style={{ display: 'hidden', margin: '16px 24px 0px 0px' }}
                  >
                    {localizedStrings.uploadDoc}
                  </RegularButton>
                </div>
              </div>
            </div>
            <div>
              <div style={{ margin: '24px 0px 0px 24px' }}>
                <CustomDataGrid
                  rows={
                    props.data && filter
                      ? filterDocuments(
                          filter,
                          props.data,
                          productTypes.map(p => p.id),
                          unitId
                        )
                      : []
                  }
                  loading={props.isLoading}
                  columns={columns[unitId]}
                  onRowClick={doc => (mouseIsOverDownloadButton ? null : props.onRowClick(doc))}
                  cursor='pointer'
                  onColumnHeaderClick={e =>
                    e.field === '__check__' ? setSelectedRows(selectedRows.length > 0 ? [] : props.data) : null
                  }
                  checkboxSelection
                  keepNonExistentRowsSelected
                  onSelectionModelChange={ids => {
                    const selectedIDs = new Set(ids)
                    const selectedRows = props.data.filter(row => selectedIDs.has(row.id))
                    setSelectedRows(selectedRows)
                  }}
                />
              </div>
            </div>
          </>
        }
        drawer={<DocumentsFilterDrawer filter={filter} setFilter={setFilter} options={props.options} />}
        resetFilter={() => {
          setCookie('filter', DEFAULT_DOCUMENTS_FILTER, { path: '/' })
          setFilter(DEFAULT_DOCUMENTS_FILTER)
        }}
      />
    </>
  )
}
