import { useMsal } from '@azure/msal-react'
import {
  CenteredDiv,
  CustomDataGrid,
  CustomSelect,
  ErrorMessage,
  RegularButton,
  RoleCheck,
  useWtxLocalization,
  PersistantFilterDiv
} from '@wavetronix/common-components'
import { useState, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import CategoriesApi from '../../api/CategoriesApi'
import CategoriesFilterDrawer, { DEFAULT_CATEGORIES_FILTER, filterCategories } from '../drawers/CategoriesFilterDrawer'
import AddCategoryModal from '../modals/AddCategoryModal'
import EditCategoryModal from '../modals/EditCategoryModal'
import { CircularProgress } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { env } from '../../index.js'

export default function CategoryManagementPage(props) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
  let largeScreenFeaturesActive = !isSmallScreen

  const { instance, accounts } = useMsal()
  const [categoryOptions, setCategoryOptions] = useState([])
  const [category, setCategory] = useState('')
  const [filter, setFilter] = useState(DEFAULT_CATEGORIES_FILTER)
  const [addCategoryModalVisible, setAddCategoryModalVisible] = useState(false)
  const [editCategoryModalVisible, setEditCategoryModalVisible] = useState(false)
  const [currentCategory, setCurrentCategory] = useState()
  let localizedStrings = useWtxLocalization()

  const columns = [
    {
      headerName: localizedStrings.name,
      field: 'name',
      flex: 1,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive
    },
    {
      headerName: localizedStrings.description,
      field: 'description',
      flex: 2,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive
    },
    {
      headerName: localizedStrings.productFamily,
      field: 'family',
      flex: 1,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive
    },
    {
      headerName: localizedStrings.order,
      field: 'order',
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
      hide: isSmallScreen
    },
    {
      headerName: localizedStrings.listOrder,
      field: 'listOrder',
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
      hide: isSmallScreen
    }
  ]

  const { data, refetch, error, isLoading } = useQuery({
    queryKey: ['categories'],
    queryFn: async () =>
      await CategoriesApi.getCategories(instance, accounts).then(res => {
        if (res.length !== 0) {
          setCategoryOptions([...new Set(res.map(c => c.categoryType))])

          if (category === '' && res.length > 0) {
            setCategory(res[0].categoryType)
          }
        }
        return res
      })
  })
  const categoryFamilies = useMemo(() => {
    let res = []
    if (data) {
      res =
        filter.showIsArchived === false
          ? data.filter(c => c.categoryType === 'Product Family' && c.isArchived === false)
          : data.filter(c => c.categoryType === 'Product Family')
    }
    return res.sort((a, b) => (a.order > b.order ? 1 : -1))
  }, [filter, data])

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }
  if (isLoading) {
    return (
      <CenteredDiv>
        <CircularProgress />
      </CenteredDiv>
    )
  }

  return (
    <RoleCheck env={env} allowedRoles={['Documents Admin', 'Gatekeeper Admin']}>
      <EditCategoryModal
        open={editCategoryModalVisible}
        resetCat={() => setCurrentCategory(null)}
        onClose={() => setEditCategoryModalVisible(false)}
        styles={props.styles}
        refetch={refetch}
        categories={categoryOptions}
        current={currentCategory}
        categoryFamlies={categoryFamilies ? categoryFamilies : []}
      />
      <AddCategoryModal
        open={addCategoryModalVisible}
        onClose={() => setAddCategoryModalVisible(false)}
        styles={props.styles}
        refetch={refetch}
        categories={categoryOptions}
        categoryFamlies={categoryFamilies ? categoryFamilies : []}
      />
      <div style={{ display: 'flex' }}>
        <PersistantFilterDiv
          defaultOpen={largeScreenFeaturesActive}
          page={
            <div style={{ width: '100%' }}>
              <div
                style={{
                  justifyContent: 'space-between'
                }}
              >
                <div style={{ display: 'flex' }}>
                  <h1 style={props.styles.pageHeader}>
                    {`${localizedStrings.manage} `}
                    <CustomSelect
                      id='categoryViewSelect'
                      style={{ minWidth: '200px', marginLeft: 15, marginRight: 15 }}
                      value={category}
                      options={categoryOptions}
                      onChange={e => {
                        setCategory(e.target.value)
                        setFilter(DEFAULT_CATEGORIES_FILTER)
                      }}
                    />
                    {` ${localizedStrings.category}`}
                  </h1>
                  <div style={{ display: 'inline', margin: '24px 0px 12px 12px' }}>
                    <RegularButton
                      id='addCategoryModalVisibleButton'
                      onClick={() => setAddCategoryModalVisible(true)}
                      style={{ marginRight: '24px', display: 'hidden' }}
                    >
                      {localizedStrings.addCategory}
                    </RegularButton>
                  </div>
                </div>
              </div>

              <CustomDataGrid
                rows={
                  data
                    ? filterCategories(
                        filter,
                        category,
                        data,
                        categoryFamilies.map(c => c.name)
                      ).sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1))
                    : []
                }
                style={{ marginLeft: '24px' }}
                loading={isLoading}
                columns={columns}
                onRowClick={cat => {
                  setCurrentCategory(cat.row)
                  setEditCategoryModalVisible(true)
                }}
                cursor='pointer'
              />
            </div>
          }
          drawer={
            <CategoriesFilterDrawer
              filter={filter}
              setFilter={setFilter}
              category={category}
              categoryFamlies={categoryFamilies ? categoryFamilies : []}
            />
          }
          resetFilter={() => setFilter(DEFAULT_CATEGORIES_FILTER)}
        />
      </div>
    </RoleCheck>
  )
}
