import axios from 'axios'
import { Buffer } from 'buffer'
import { env } from '../index.js'

let instance = null

class NexusAssetsApi {
  getAssets = async (msalInstance, accounts) => {
    let credentials = Buffer.from(
      `${env.basicAuthentication.gatekeeperUserName}:${env.basicAuthentication.gatekeeperPassword}`
    ).toString('base64')

    return await axios
      .get(`${env.urls.gatekeeperURL}/api/nexusassets/all/products`, {
        headers: { Authorization: `Basic ${credentials}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  uploadAsset = async (msalInstance, accounts, contentType, file) => {
    let credentials = Buffer.from(
      `${env.basicAuthentication.gatekeeperUserName}:${env.basicAuthentication.gatekeeperPassword}`
    ).toString('base64')

    return await axios({
      url: `${env.urls.gatekeeperURL}/api/nexusassets/products?contentType=${encodeURIComponent(contentType)}`,
      method: 'POST',
      data: file,
      headers: {
        'Content-Type': contentType,
        Authorization: `Basic ${credentials}`,
        timeout: 30000
      }
    })
  }

  deleteAsset = async (msalInstance, accounts, filePath, fileName) => {
    let credentials = Buffer.from(
      `${env.basicAuthentication.gatekeeperUserName}:${env.basicAuthentication.gatekeeperPassword}`
    ).toString('base64')

    return await axios
      .delete(`${env.urls.gatekeeperURL}/api/nexusassets/products/${fileName}`, {
        headers: { Authorization: `Basic ${credentials}` },
        timeout: 30000
      })
      .then(res => res.data)
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new NexusAssetsApi()
  }
  return instance
}

export default getInstance()
