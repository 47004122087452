import React, { useState } from 'react'
import DocumentsTypeDisplayPage from './DocumentsTypeDisplayPage'
import { useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import { CenteredDiv, ErrorMessage } from '@wavetronix/common-components'
import DocumentsApi from '../../api/DocumentsApi'
import UploadDocumentModal from '../modals/UploadDocumentModal'
import DocumentsListDisplayPage from './DocumentsListDisplayPage'
import { CircularProgress } from '@mui/material'
import { useCookies } from 'react-cookie'
import { DEFAULT_DOCUMENTS_FILTER } from '../drawers/DocumentFilterDrawer'
import { useParams } from 'react-router-dom'

export default function AdminDocumentsPage(props) {
  const { instance, accounts } = useMsal()
  const { unitId } = useParams()
  const [documentUploadModalOpen, setDocumentUploadModalOpen] = useState(false)
  const [filter, setFilter] = useState(DEFAULT_DOCUMENTS_FILTER)
  const [cookies, setCookie] = useCookies(['filter', 'fromFamily'])

  const { data, isLoading, refetch, error } = useQuery({
    queryKey: ['documents'],
    queryFn: async () =>
      await DocumentsApi.getAdminDocuments(instance, accounts, unitId).then(res => {
        return res.sort((a, b) => {
          if (a.order > b.order) {
            return 1
          } else {
            return -1
          }
        })
      })
  })

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }

  if (isLoading) {
    return (
      <CenteredDiv>
        <CircularProgress />
      </CenteredDiv>
    )
  }

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '100%' }}>
        <UploadDocumentModal
          open={documentUploadModalOpen}
          onClose={() => setDocumentUploadModalOpen(false)}
          styles={props.styles}
          refetch={refetch}
        />
        {props.showFullList ? (
          <DocumentsListDisplayPage
            openCreateModal={() => setDocumentUploadModalOpen(true)}
            refetch={refetch}
            filter={filter}
            setFilter={setFilter}
            cookies={cookies}
            setCookie={setCookie}
            onRowClick={file => {
              setCookie('filter', filter, { path: '/' })
              window.open(`/details/${unitId}?id=${encodeURIComponent(file.id)}&prev=search`)
            }}
            data={data ? data : []}
            loading={isLoading}
            options={['All Levels', 'Advanced', 'Intermediate', 'Basic']}
          />
        ) : (
          <DocumentsTypeDisplayPage
            state={props.productState}
            setState={props.setProductState}
            cookies={cookies}
            setCookie={setCookie}
          />
        )}
      </div>
    </div>
  )
}
