import { useMsal } from '@azure/msal-react'
import { Switch } from '@mui/material'
import { useSnackbar } from 'notistack'
import { CustomDataGrid, useWtxLocalization, SnackbarVariants } from '@wavetronix/common-components'
import { useState, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { filterUsers } from '../drawers/UserFilterDrawer'
import GroupsApi from '../../api/GroupsApi'
import DocumentsApi from '../../api/DocumentsApi'
import UsersApi from '../../api/UsersApi'
import { useParams } from 'react-router-dom'

export default function DocumentsOverridesModal({
  document,
  setButtonsDisabled,
  buttonsDisabled,
  userOverrides,
  filter,
  docRefetch
}) {
  const { instance, accounts } = useMsal()
  const { unitId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  let localizedStrings = useWtxLocalization()
  const [pageSize, setPageSize] = useState(50)
  const [usersGroupsOverrides, setUsersGroupsOverrides] = useState({})
  const [filteredUsers, setFilteredUsers] = useState([])
  const { data: groupsData } = useQuery({
    queryKey: ['groupsAllQuery'],
    queryFn: async () => await GroupsApi.getGroups(instance, accounts),
    enabled: unitId === 'nexus'
  })
  const { data: users, isLoading: usersLoading } = useQuery({
    queryKey: ['users'],
    queryFn: async () => await UsersApi.getUsers(instance, accounts)
  })

  useEffect(() => {
    if (groupsData && users && document && userOverrides) {
      var usersMap = {}
      for (let user of users) {
        //default user override to none
        usersMap[user.id] = 'none'

        //if user override on update set to updateAccess
        if (userOverrides.includes(user.id)) {
          usersMap[user.id] = 'userOverrideAccess'
        }

        //if user is in a group with override access reset to groupOverrideAccess
        for (let group of groupsData) {
          if (user.groups && user.groups.includes(group.id) && group.documents.includes(document.id)) {
            usersMap[user.id] = 'groupOverrideAccess'
          }
        }
      }
      setUsersGroupsOverrides(usersMap)
    }
  }, [groupsData, users, document, userOverrides])

  useEffect(() => {
    if (users) {
      setFilteredUsers(filterUsers(filter, users))
    }
  }, [filter, users])

  const columns = [
    {
      headerName: localizedStrings.override,
      field: 'hasAccess',
      flex: 1,
      valueGetter: user => user.row.id,
      sortComparator: (a, b) => {
        if (usersGroupsOverrides[a] === usersGroupsOverrides[b]) {
          return 0
        } else if (
          (usersGroupsOverrides[b] === 'groupOverrideAccess' && usersGroupsOverrides[a] === 'userOverrideAccess') ||
          (usersGroupsOverrides[b] === 'groupOverrideAccess' && usersGroupsOverrides[a] === 'none') ||
          (usersGroupsOverrides[b] === 'userOverrideAccess' && usersGroupsOverrides[a] === 'none')
        ) {
          return 1
        } else {
          return -1
        }
      },
      renderCell: user => {
        let checkValue =
          usersGroupsOverrides && usersGroupsOverrides[user.row.id] && usersGroupsOverrides[user.row.id].includes('Access')
            ? true
            : false
        let colorValue =
          usersGroupsOverrides && usersGroupsOverrides[user.row.id] && usersGroupsOverrides[user.row.id].includes('Access')
            ? 'primary'
            : 'secondary'
        let disabledValue =
          usersGroupsOverrides && usersGroupsOverrides[user.row.id] && usersGroupsOverrides[user.row.id].includes('group')
        return (
          <Switch
            checked={checkValue}
            color={colorValue}
            onChange={e => onSwitchChange(e, user.row)}
            disabled={buttonsDisabled || disabledValue}
          />
        )
      }
    },
    {
      headerName: localizedStrings.firstName,
      field: 'givenName',
      flex: 2
    },
    {
      headerName: localizedStrings.lastName,
      field: 'surname',
      flex: 2
    },
    {
      headerName: localizedStrings.email,
      field: 'email',
      flex: 2
    }
  ]

  async function addOverrides(userId) {
    await DocumentsApi.addUserOverride(instance, accounts, document.id, userId, unitId)
      .then(async response => {
        enqueueSnackbar(localizedStrings.snackbar.overrideSuccess, SnackbarVariants.SUCCESS)
      })
      .catch(error => enqueueSnackbar(localizedStrings.snackbar.overrideFailure, SnackbarVariants.ERROR))
  }

  async function removeOverrides(userId) {
    await DocumentsApi.removeUserOverride(instance, accounts, document.id, userId, unitId)
      .then(async response => {
        enqueueSnackbar(localizedStrings.snackbar.overrideRemoveSuccess, SnackbarVariants.SUCCESS)
      })
      .catch(error => enqueueSnackbar(localizedStrings.snackbar.overrideRemoveFailure, SnackbarVariants.ERROR))
  }

  async function onSwitchChange(e, user) {
    setButtonsDisabled(true)
    if (e.target.checked) {
      await addOverrides(user.id)
    } else {
      await removeOverrides(user.id)
    }
    await docRefetch()
    setButtonsDisabled(false)
  }

  return (
    <div
      style={{
        width: '100%'
      }}
    >
      <CustomDataGrid
        loading={usersLoading}
        pageSize={pageSize}
        onPageSizeChange={p => setPageSize(p.pageSize)}
        columns={columns}
        rows={filteredUsers}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'hasAccess',
                sort: 'asc'
              }
            ]
          }
        }}
      />
    </div>
  )
}
