import { Tab, Tabs } from '@mui/material'
import { useNavigate, useParams } from 'react-router'
import '../index.css'
import { useMemo } from 'react'
import { useWtxLocalization, WtxColors, checkHasAccess } from '@wavetronix/common-components'
import { env } from '../index.js'

const classes = {
  tab: {
    textTransform: 'none',
    fontFamily: 'Klavika',
    fontSize: '16px',
    alignItems: 'end',
    padding: '0px 16px 0px 0px',
    color: 'black'
  },
  tabParent: {
    minWidth: '190px'
  }
}
export const TAB_OPTIONS = [
  {
    roles: ['Gatekeeper Admin', 'Documents Admin'],
    id: 'documentsTab',
    label: 'documents',
    value: 'documents',
    validUnits: ['nexus']
  },
  {
    roles: ['Gatekeeper Admin', 'Documents Admin', 'Manufacturing Admin'],
    id: 'searchTab',
    label: 'search',
    value: 'search',
    validUnits: ['nexus', 'manufacturing']
  },
  {
    roles: ['Gatekeeper Admin', 'Documents Admin'],
    id: 'usersTab',
    label: 'users',
    value: 'users',
    validUnits: ['nexus']
  },
  {
    roles: ['Gatekeeper Admin', 'Documents Admin'],
    id: 'categoriesTab',
    label: 'categories',
    value: 'categories',
    validUnits: ['nexus']
  },
  {
    roles: ['Gatekeeper Admin', 'Documents Admin'],
    id: 'crmProductsTab',
    label: 'crmproducts',
    value: 'crmproducts',
    validUnits: ['nexus']
  },
  {
    roles: ['Gatekeeper Admin', 'Documents Admin'],
    id: 'assetsTab',
    label: 'assets',
    value: 'assets',
    validUnits: ['nexus']
  }
]

export default function PageNavbar({ value, setPageState, accounts }) {
  const localizedStrings = useWtxLocalization()
  const navigate = useNavigate()
  const { unitId } = useParams()

  const userTabOptions = useMemo(() => {
    return TAB_OPTIONS.filter(option => {
      return checkHasAccess(option.roles, accounts, env) && option.validUnits.includes(unitId)
    })
  }, [accounts, unitId])

  const onChange = option => {
    if (option.validUnits.includes(unitId)) {
      navigate(`/${option.value}/${unitId}`)
    } else {
      console.log('error in change path')
      return
    }

    setPageState(option.value)
  }

  if (userTabOptions.length > 1) {
    return (
      <Tabs
        value={userTabOptions.map(opt => opt.value).includes(value) ? value : userTabOptions[0].value}
        TabIndicatorProps={{
          sx: {
            backgroundColor: WtxColors.IQ_BLUE
          }
        }}
        orientation='vertical'
        sx={classes.tabParent}
      >
        {userTabOptions.map(option => {
          return (
            <Tab
              key={option.id}
              id={option.id}
              value={option.value}
              sx={classes.tab}
              label={localizedStrings[option.label]}
              onClick={() => onChange(option)}
            />
          )
        })}
      </Tabs>
    )
  } else {
    return null
  }
}
