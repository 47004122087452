import { useMsal } from '@azure/msal-react'
import { CircularProgress, Grid } from '@mui/material'
import {
  CenteredDiv,
  ErrorMessage,
  RoleCheck,
  SnackbarVariants,
  useWtxLocalization,
  PersistantFilterDiv
} from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import CategoriesApi from '../../api/CategoriesApi'
import DocumentsApi from '../../api/DocumentsApi'
import DocumentsOverridesModal from '../modals/DocumentsOverridesModal'
import UserFilterDrawer, { DEFAULT_USERS_FILTER } from '../drawers/UserFilterDrawer'
import { env } from '../../index.js'
import MultiDeleteModal from '../modals/MultiDeleteModal'
import CrmProductsApi from '../../api/CrmProductsApi'
import { DocumentInfoSection, DocumentNameSection } from '../controls/DocumentInfo'

const BASE_DOC_DEFAULT = {
  id: '',
  description: '',
  fileName: '',
  products: [],
  crmProducts: [],
  order: 1
}

export default function DocumentViewerPage(props) {
  const { instance, accounts } = useMsal()
  const navigate = useNavigate()
  const { unitId } = useParams()
  const [searchParams] = useSearchParams()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const [doc, setDoc] = useState(BASE_DOC_DEFAULT)
  const [overridesCopy, setOverridesCopy] = useState()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [filter, setFilter] = useState(DEFAULT_USERS_FILTER)
  const [isUploading, setIsUploading] = useState(false)

  let localizedStrings = useWtxLocalization()

  let docId = searchParams.get('id')

  const {
    data: categories,
    isLoading: categoriesLoading,
    error: categoriesError
  } = useQuery({
    queryKey: ['categories'],
    queryFn: async () => {
      let categories = await CategoriesApi.getCategories(instance, accounts)
      return categories.sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1))
    }
  })

  const {
    data: document,
    isLoading: documentLoading,
    refetch: docRefetch,
    error: documentError
  } = useQuery({ queryKey: ['document'], queryFn: async () => await DocumentsApi.getDocument(instance, accounts, docId, unitId) })

  const {
    data: crmProducts,
    isLoading: crmProductsLoading,
    error: crmProductsError
  } = useQuery({
    queryKey: ['crmProducts'],
    queryFn: async () => {
      let crm = await CrmProductsApi.getCrmProducts(instance, accounts)
      return crm.sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1))
    }
  })

  useEffect(() => {
    if (document) {
      setDoc(document)
      if (!document.userOverrides) document.userOverrides = []
      setOverridesCopy(document.userOverrides)
    }
  }, [document])

  async function uploadWithFormData(file) {
    setIsUploading(true)
    if (file) {
      const formData = new FormData()
      formData.append('document', file)
      let fileKey = enqueueSnackbar(`Uploading file to blob storage...`, SnackbarVariants.LOADING)
      await DocumentsApi.updateDocument(instance, accounts, 'multipart/form-data', formData, doc.id, unitId)
        .then(response => {
          closeSnackbar(fileKey)
          enqueueSnackbar(localizedStrings.snackbar.docUploaded, SnackbarVariants.SUCCESS)
        })
        .catch(error => {
          closeSnackbar(fileKey)
          enqueueSnackbar(error.response.data, SnackbarVariants.ERROR)
        })
    }

    setIsUploading(false)
  }

  function createDocInfo() {
    let docInfo = {
      ...doc
    }

    if (unitId === 'nexus') {
      docInfo = {
        ...docInfo,
        userOverrides: overridesCopy
      }
    } else if (unitId === 'manufacturing') {
    }

    console.log(docInfo)

    return docInfo
  }

  if (documentLoading || categoriesLoading || !document || crmProductsLoading) {
    return (
      <CenteredDiv>
        <CircularProgress />
      </CenteredDiv>
    )
  }

  if (documentError || categoriesError || crmProductsError) {
    if (documentError) {
      return (
        <CenteredDiv>
          <ErrorMessage error={documentError} />
        </CenteredDiv>
      )
    } else if (categoriesError) {
      return (
        <CenteredDiv>
          <ErrorMessage error={categoriesError} />
        </CenteredDiv>
      )
    } else if (crmProductsError) {
      return (
        <CenteredDiv>
          <ErrorMessage error={crmProductsError} />
        </CenteredDiv>
      )
    }
  }

  return (
    <>
      <MultiDeleteModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        deleteDocuments={[doc]}
        refetchDocuments={async () => {
          navigate('..')
        }}
      />

      {
        {
          nexus: (
            <RoleCheck env={env} allowedRoles={['Documents Admin', 'Gatekeeper Admin']}>
              <PersistantFilterDiv
                page={
                  <div>
                    <DocumentNameSection
                      unitId={unitId}
                      doc={doc}
                      uploadWithFormData={uploadWithFormData}
                      isUploading={isUploading}
                      searchParams={searchParams}
                    />
                    <Grid container>
                      <Grid item xl={4} lg={5} md={5} sm={12} xs={12}>
                        <DocumentInfoSection
                          unitId={unitId}
                          doc={doc}
                          setDoc={setDoc}
                          isUploading={isUploading}
                          categories={categories}
                          crmProducts={crmProducts}
                          setDeleteModalOpen={setDeleteModalOpen}
                          setButtonsDisabled={setButtonsDisabled}
                          createDocInfo={createDocInfo}
                          docRefetch={docRefetch}
                        />
                      </Grid>
                      <Grid item xl={8} lg={7} md={7} sm={12} xs={12}>
                        <DocumentsOverridesModal
                          document={doc}
                          setButtonsDisabled={e => setButtonsDisabled(e)}
                          buttonsDisabled={buttonsDisabled}
                          userOverrides={overridesCopy}
                          filter={filter}
                          docRefetch={docRefetch}
                        />
                      </Grid>
                    </Grid>
                  </div>
                }
                drawer={<UserFilterDrawer filter={filter} setFilter={setFilter} />}
                resetFilter={() => {
                  setFilter(DEFAULT_USERS_FILTER)
                }}
              />
            </RoleCheck>
          ),
          manufacturing: (
            <RoleCheck env={env} allowedRoles={['Manufacturing Admin']}>
              <div>
                <DocumentNameSection
                  unitId={unitId}
                  doc={doc}
                  uploadWithFormData={uploadWithFormData}
                  isUploading={isUploading}
                  searchParams={searchParams}
                />
                <Grid container>
                  <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                    <DocumentInfoSection
                      unitId={unitId}
                      doc={doc}
                      setDoc={setDoc}
                      isUploading={isUploading}
                      categories={categories}
                      crmProducts={crmProducts}
                      setDeleteModalOpen={setDeleteModalOpen}
                      setButtonsDisabled={setButtonsDisabled}
                      createDocInfo={createDocInfo}
                      docRefetch={docRefetch}
                    />
                  </Grid>
                </Grid>
              </div>
            </RoleCheck>
          )
        }[unitId]
      }
    </>
  )
}
