import { MenuAppBar, NotFoundPage } from '@wavetronix/common-components'
import { BrowserRouter, Route, Routes, Navigate, Outlet } from 'react-router-dom'
import { env } from '../index.js'
import { useMsal } from '@azure/msal-react'
import { useEffect, useState } from 'react'
import PageNavbar from './PageNavbar'
import NexusAssetsPage from './NexusAssets/NexusAssetsPage'
import UsersAccessPage from './pages/UsersAccessPage'
import DocumentsPage from './DocumentsPage'
import CategoryManagementPage from './pages/CategoryManagementPage'
import CrmProductsManagementPage from './pages/CrmProductsManagementPage'
import ProductViewerPage from './pages/ProductViewerPage'
import DocumentViewerPage from './pages/DocumentViewerPage'
import version from '../env/version.json'
const drawerWidth = 300

const classes = {
  paper: {
    width: drawerWidth
  },
  dataGrid: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none'
    }
  },
  filterTitle: {
    fontFamily: 'Klavika',
    fontSize: 22
  },
  title: {
    fontFamily: 'Klavika',
    fontSize: 26
  },
  inlineTitle: {
    fontFamily: 'Klavika',
    fontSize: '26px',
    display: 'inline'
  },
  pageHeader: {
    marginLeft: '24px',
    marginTop: '30px',
    fontSize: '24px',
    flex: 1,
    display: 'inline',
    justifyContent: 'left'
  },
  tableRow: {
    cursor: 'pointer'
  }
}

function MainPage() {
  const { accounts } = useMsal()
  const [productState, setProductState] = useState({
    current: ''
  })
  const [pageState, setPageState] = useState('')

  useEffect(() => {
    let windowPath = window.location.pathname.split('/').filter(part => part && part !== '')
    if (windowPath.length > 0) {
      setPageState(windowPath[0])
    } else {
      setPageState('documents')
    }
  }, [])

  if (!pageState || pageState === '') {
    return <div>Loading...</div>
  } else {
    return (
      <div>
        <MenuAppBar env={env} fromCompany={env.basicAuthentication.fromCompany} />
        <div className='content'>
          <BrowserRouter
            future={{
              v7_startTransition: true,
              v7_relativeSplatPath: true
            }}
          >
            <Routes>
              <Route path='/' element={<Navigate to='/documents/nexus' replace />} />
              <Route path='/documents/manufacturing' element={<Navigate to='/search/manufacturing' replace />} />
              <Route path='/documents/*' element={<Navigate to='/documents/nexus' replace />} />
              <Route path='/search/*' element={<Navigate to='/search/nexus' replace />} />
              <Route path='/nexus' element={<Navigate to='/documents/nexus' replace />} />
              <Route path='/manufacturing' element={<Navigate to='/search/manufacturing' replace />} />

              <Route
                element={
                  <div style={{ display: 'flex' }}>
                    <PageNavbar value={pageState} setPageState={setPageState} accounts={accounts} />
                    <div style={{ margin: '10px', width: '100%' }}>
                      <Outlet />
                    </div>
                  </div>
                }
              >
                <Route
                  path='/documents/:unitId'
                  element={
                    <DocumentsPage
                      styles={classes}
                      showFullList={false}
                      productState={productState}
                      setProductState={setProductState}
                    />
                  }
                />
                <Route
                  path='/search/:unitId'
                  element={
                    <DocumentsPage
                      styles={classes}
                      showFullList={true}
                      productState={productState}
                      setProductState={setProductState}
                    />
                  }
                />
                <Route path='/users/:unitId' element={<UsersAccessPage styles={classes} />} />
                <Route path='/categories/:unitId' element={<CategoryManagementPage styles={classes} />} />
                <Route path='/crmproducts/:unitId' element={<CrmProductsManagementPage styles={classes} />} />
                <Route path='/assets/:unitId' element={<NexusAssetsPage styles={classes} />} />
              </Route>
              <Route path='/details/:unitId' element={<DocumentViewerPage styles={classes} />} />
              <Route path='/byproduct/:unitId' element={<ProductViewerPage styles={classes} />} />

              <Route path='/version' element={<div>{version.version}</div>} />
              {/* This wildcard route needs to stay at the bottom */}
              <Route path='*' component={NotFoundPage} />
            </Routes>
          </BrowserRouter>
        </div>
      </div>
    )
  }
}
export default MainPage
